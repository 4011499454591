/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.center{text-align:center}
.h-15{height: 3rem;}
.top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }

    .map-container{
      height: 900px;
    }
    .apple-btn{
      background-Color: black;
      color:white;
      padding: 10px;
      border: 1px solid black;
      line-height: 15px;
      font-size: 15px;
      border-radius:3px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: auto;
      padding: 13px;
      height: 40px;
    }
    .apple-btn i{
      font-size: 20px;
    }
    .grayText{color:#7b8392; font-size: 0.750rem;}
    #appleid-signin{
      height: 41px;
      width: 55%;
      margin: auto;
      margin-top: 14px;
      border-radius: 3px !important;
    }
    .class-for-1{background-color: gold;}
    .class-for-22{background-color: silver;}
    .class-for-32{background-color: #CD7F32;}
    .items-top{align-items: flex-start;}
    .red-color{color: red; font-weight: bold;}
    .listImage{height: 250px; width: 250px;}
    .listView{padding:0 100px}
    .see_hotel{float:right; margin-top:-2rem;}
    